import get from 'lodash.get';

// === Data === //
import { eventNoEventSiteData } from '@graphql/EventNoEventSite';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { onlineEventHeader } from '@DTO/sections/event/OnlineEventHeader';
import { noOnlineEventNewsletterHeader } from '@DTO/sections/event/NoOnlineEventNewsletterHeader';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { eventBodySection } from './sections/event/EventBodySection';
import { recommendedArticles } from '@DTO/sections/RecommendedArticles';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const EventNoEventSiteDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const dataQuery = eventNoEventSiteData();
    currentPageData = dataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const articlesEntity = get(currentPageData, 'drupalData.blogArticles.entities', []).sort(
    (a, b) => {
      const dateA: any = new Date(get(a, 'fieldPublicationDate.value', ''));
      const dateB: any = new Date(get(b, 'fieldPublicationDate.value', ''));

      return dateB - dateA;
    }
  );
  const headerType = get(
    nodeEntity,
    'fieldOnlineEventHeader.entity.entityBundle',
    'no_online_event'
  );
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      headerType === 'no_online_event'
        ? noOnlineEventNewsletterHeader(nodeEntity, 'fieldOnlineEventHeader')
        : onlineEventHeader(nodeEntity, 'fieldOnlineEventHeader'),
      eventBodySection(nodeEntity, 'fieldSectionEvent'),
      recommendedArticles(nodeEntity, articlesEntity, 'fieldRecommendedArticles'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
