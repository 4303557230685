import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const eventNoEventSiteData = () => {
  const data = useStaticQuery(graphql`
    query eventNoEventSiteQuery {
      drupalData {
        nodeQuery(
          filter: { conditions: { field: "type", value: "event_no_event_site" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_NodeEventNoEventSite {
              entityLabel

              fieldForm {
                entity {
                  uuid
                }
              }
              fieldFormOffer
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldOnlineEventHeader {
                entity {
                  ...ParagraphOnlineEventHeader
                  ...ParagraphNoOnlineEvent
                }
              }
              fieldRecommendedArticles {
                entity {
                  ...ParagraphRecommendedArticles
                }
              }
              fieldSectionEvent {
                entity {
                  ...ParagraphEventDisclaimer
                  ...ParagraphEventCopy
                  ...ParagraphEventConversionBanner
                  ...ParagraphEventImage
                }
              }

              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
        blogArticles: nodeQuery(
          limit: 10000
          filter: { conditions: { field: "type", value: "blog_page" } }
        ) {
          entities {
            ... on drupalData_NodeBlogPage {
              entityLabel
              fieldBlogCategory {
                entity {
                  ... on drupalData_TaxonomyTermBlogCategory {
                    entityBundle
                    name
                    uuid
                  }
                }
              }
              fieldPublicationDate {
                date
                value
              }
              fieldReadingTime
              fieldShortIntroCopy {
                processed
              }
              fieldTeaserImage {
                alt
                derivative(style: WEBP) {
                  url
                }
                title
                url
              }
              fieldRefAuthor {
                entity {
                  ...NodeAuthor
                }
              }
              fieldSectionBlog {
                entity {
                  ...ParagraphArticle
                  ...ParagraphBlogAlertBox
                  ...ParagraphBlogImage
                  ...ParagraphBlogTextImageVideo
                  ...ParagraphBlogVideo
                  ...ParagraphBlogTestimonial
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
