import get from 'lodash.get';

import { ctaVariants } from '@utils';
import { IEventBodySection } from '@components/sections/Event/EventBodySection/EventBodySection.d';

export const eventBodySection = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}`, []);

  const mappedData: IEventBodySection = {
    type: 'event_body_section',
    components: data.map(component => ({
      type: get(component, 'entity.entityBundle', ''),
      description: get(component, 'entity.fieldCopy.processed', ''),
      title: get(component, 'entity.fieldTitle', ''),
      image: get(component, 'entity.fieldImage', undefined) ? {
        url: get(component, 'entity.fieldImage.url', ''),
        alt: get(component, 'entity.fieldImage.alt', ''),
        title: get(component, 'entity.fieldImage.title', ''),
        webp: get(component, 'entity.fieldImage.derivative.url', ''),
      } : undefined,
      imageCaption: get(component, 'entity.fieldImageCaption', ''),
      cta: get(component, 'entity.fieldCtaPink', undefined) ? {
        variant: ctaVariants[get(component, 'entity.fieldCtaPink.entity.entityBundle', 'cta_pink')],
        label: get(component, 'entity.fieldCtaPink.entity.fieldLinkCta.title', ''),
        href: get(component, 'entity.fieldCtaPink.entity.fieldLinkCta.url.path', '/'),
      }: undefined,
      headline: get(component, 'entity.fieldHeadline.processed', ''),
      subheadline: get(component, 'entity.fieldUpperSubheadline', ''),
    }))
  };

  return mappedData;
}