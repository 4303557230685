import get from 'lodash.get';

import { ctaVariants } from '@utils';
import { IJobOfferIntro } from '@components/sections/Career/JobOfferIntro/JobOfferIntro.d';

export const onlineEventHeader = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: IJobOfferIntro = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    department: get(data, 'fieldEventTag', ''),
    place: get(data, 'fieldEventDate', ''),
    image: get(data, 'fieldImage', undefined)
      ? {
          alt: get(data, 'fieldImage.alt', ''),
          title: get(data, 'fieldImage.title', ''),
          url: get(data, 'fieldImage.url', ''),
          webp: get(data, 'fieldImage.derivative.url', ''),
        }
      : undefined,
    cta: get(data, 'fieldCtaPink', undefined)
      ? {
          variant: ctaVariants[get(data, 'fieldCtaPink.entity.entityBundle', 'cta_pink')],
          label: get(data, 'fieldCtaPink.entity.fieldLinkCta.title', ''),
          href: get(data, 'fieldCtaPink.entity.fieldLinkCta.url.path', '/'),
        }
      : undefined,
  };

  return mappedData;
};
