import get from 'lodash.get';

import { form } from '@DTO/sections/form/Form';
import { INoOnlineEventNewsletterHeader } from '@components/sections/Event/NoOnlineEventNewsletterHeader/NoOnlineEventNewsletterHeader.d';
import { getDataFromPreview } from '@helpers/form';

export const noOnlineEventNewsletterHeader = (
  nodeData,
  fieldName: string,
  previewData?: unknown
) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const formId = get(data, 'fieldForm.entity.uuid', '');

  const mappedData: INoOnlineEventNewsletterHeader = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    form: form(formId, getDataFromPreview(previewData)),
  };

  return mappedData;
};
